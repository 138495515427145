import React, {useState, useEffect, useRef} from "react";
import {TableRow, TableCell, IconButton, Tooltip, TextField, Box, Button, Select, MenuItem, CircularProgress} from "@mui/material";
import { Paragliding, Feed, Flag, Summarize, MailRounded } from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useStyles} from "@styles/table.style";
import Dialog from "@components/Dialog";
import serviceValuations from '@services/serviceValuations'
import serviceFiles from '@services/serviceFiles'
import {snackActions} from '@helpers/snackbarUtils';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import serviceIncidents from '@services/serviceIncidents';
import serviceCycleJourneysParticipants from '@services/serviceCycleJourneysParticipants';
import PrintIcon from '@mui/icons-material/Print'
import serviceParticipants from '@services/serviceParticipants'
import { useNavigate } from 'react-router-dom'
import serviceCycles from '@services/serviceCycles'
import serviceCycleJourneys from '@services/serviceCycleJourneys';

const ParticipantsData = ({formData, setFormData, closeJourney, evaluatorTypeList}) => {  
  const {t} = useTranslation();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedbackInd, setFeedbackInd] = useState();
  const [nameFeedback, setNameFeedback] = useState();
  const [participantIndex, setParticipantIndex] = useState();
  const [comentJourney, setComentJourney] = useState();
  const [paracaidistas, setParacaidistas] = useState();
  const [participants, setParticipants] = useState();
  const [assistantMedia, setAssistantMedia] = useState([]);
  const [queTeLLevas, setQueTeLLevas] = useState(0);
  const [globalGrade, setGlobalGrade] = useState(0);
  const [assistance, setAssistance] = useState([]);
  const [valuations, setValuations] = useState([])
  const idEvaluatorTypeRef = useRef()
  const [loading, setLoading] = useState(false);

  const [openIncidentDialog, setOpenIncidentDialog] = useState(false);
  const [currentIncident, setCurrentIncident] = useState({ journey: {}, index: null, idCycle: null});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [openAssistanceDialog, setOpenAssistanceDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
 
  const navigate = useNavigate()
  
  const deleteIncident = async () => {
    const incidentId = currentIncident.journey.cycleJourneyParticipantIncident;

    if (!incidentId) {
        snackActions.error(t("INCIDENT.DELETE_ERROR_ID_NULL"));
        return;
    }

    // Cerrar el diálogo inmediatamente
    setOpenDeleteConfirmDialog(false);
    setOpenIncidentDialog(false);

    try {
        const response = await serviceIncidents.deleteIncident({ idIncident: incidentId });

        if (!response.error) {
            // Actualizar el estado para reflejar la eliminación de la incidencia
            setFormData(prevState => {
                const updatedCycleJourneys = prevState.cycleJourneys.map((journey, index) => {
                    if (index === currentIncident.index) {
                        const updatedParticipants = journey.cycleJourneyParticipants.map(participant => {
                            if (participant.idParticipant === currentIncident.journey.idParticipant) {
                                return {
                                    ...participant,
                                    cycleJourneyParticipantIncidentDesc: '',
                                    cycleJourneyParticipantIncident: null
                                };
                            }
                            return participant;
                        });
                        return {
                            ...journey,
                            cycleJourneyParticipants: updatedParticipants
                        };
                    }
                    return journey;
                });
                return {
                    ...prevState,
                    cycleJourneys: updatedCycleJourneys
                };
            });
        } else {
            snackActions.error(t("INCIDENT.DELETE_ERROR"));
        }
    } catch (error) {
        snackActions.error(t("INCIDENT.DELETE_ERROR"));
        console.error("Error deleting incident:", error);
    }
  };

  const handleChangeAssistance = async (participant) => {
    const updatedParticipant = {
      ...participant,
      cycleJourneyAssistance: !participant.cycleJourneyAssistance,
    };

    const data = {
      idCycleJourneysParticipants: updatedParticipant.idCycleJourneysParticipants,
      idCycleJourney: updatedParticipant.idCycleJourney,
      idParticipant: updatedParticipant.idParticipant,
      cycleJourneyParticipantRate: updatedParticipant.cycleJourneyParticipantRate,
      cycleJourneyParticipantRemarks: updatedParticipant.cycleJourneyParticipantRemarks,
      cycleJourneyPartipantIncident: updatedParticipant.cycleJourneyPartipantIncident,
      cycleJourneyAssistance: updatedParticipant.cycleJourneyAssistance,
    };

    setLoading(true);
    let response;

    if (!updatedParticipant.cycleJourneyAssistance) {
        response = await serviceCycleJourneysParticipants.deleteCycleJourneyParticipant(updatedParticipant.idCycleJourneysParticipants);
    } else {
        response = await serviceCycleJourneysParticipants.updateCycleJourneParticipant(data);
    }

    if (!response.error) {
        // Actualizar el estado de formData local
        setFormData(prevState => {
          const updatedCycleJourneys = prevState.cycleJourneys.map(journey => {
            if (journey.idCycleJourney === updatedParticipant.idCycleJourney) {
              return {
                ...journey,
                cycleJourneyParticipants: journey.cycleJourneyParticipants.map(p =>
                  p.idParticipant === updatedParticipant.idParticipant ? updatedParticipant : p
                ),
              };
            }
            return journey;
          });
          return { ...prevState, cycleJourneys: updatedCycleJourneys };
        });
  
        // Refrescar formData completo desde serviceCycles.getCycleDetail
        let { data, error } = await serviceCycles.getCycleDetail(formData.idCycle, false, true);
        if (!error) {
            setFormData(data);
        }
  } else {
        snackActions.error(t('incident.not.created'));
    }

    setLoading(false);
  };

  const fetchCycleJourneyDetails = async(idCycleJourney) => {
    const { data, error } = await serviceCycleJourneys.getCycleJourneyDetails(idCycleJourney);
    if (!error) {
      return data;
    }
  }

  const fetchCycleJourneysParticipantsDetails = async(idParticipant) => {
    const { data, error } = await serviceCycleJourneysParticipants.getJourneysParticipants({
      pageSize: 1000,
      pageNumber: 0,
      globalSearch: '',
      search: [
        {
            searchField: 'idParticipant',
            searchMode: 'equalTo',
            searchValue: idParticipant
        }
      ]
    });
    if (!error) {
      return data;
    }
  }

  
  const handleOpenAssistanceDialog = (participant) => {
    if (!participant.cycleJourneyAssistance) {
      // Si la asistencia es falsa, abrir el diálogo para confirmar la eliminación de la asistencia
      setCurrentParticipant(participant);
      setOpenAssistanceDialog(true);
    } else {
      // Si la asistencia es verdadera, cambiar asistencia directamente
      handleChangeAssistance(participant);
    }
  };
  
  const handleConfirmAssistanceChange = async () => {
    if (currentParticipant) {
        setOpenLoading(true);
        await handleChangeAssistance(currentParticipant);
        setOpenAssistanceDialog(false);
        setOpenLoading(false);
    }
};



  const handleOpenConfirmDialog = (participant) => {
    setCurrentParticipant(participant);
    setOpenConfirmDialog(true);
 };

  const getFeedbackTooltipTitle = () => {
    return formData.hasFeedback ? t('LABEL.DOWNLOAD_FEEDBACK') : t('LABEL.DOWNLOAD_TEMPLATE_FEEDBACK');
  };

  const handleFlag = () => {
    const handleSuccess = () => {
      if (formData.hasFeedback) {
        snackActions.success(t('LABEL.DOWNLOAD_SUCCESS_FEEDBACK'));
      } else {
        snackActions.success(t('LABEL.DOWNLOAD_SUCCESS_TEMPLATE_FEEDBACK'));
      }
    };
  
    if (formData.hasFeedback === false || formData.hasFeedback === undefined) {
      getDocument({ idCycle: formData.idCycle }, 'feedback')
        .then(handleSuccess)
        .catch(error => console.error('Error during document download:', error));
    } else if (formData.hasFeedback === true) {
      getCycleFeedback(formData.idCycle)
        .then(handleSuccess)
        .catch(error => console.error('Error during feedback download:', error));
    }
  };
 
  const getCycleFeedback = async (idCycle) => {
    try {
      const response = await fetch(`/api/getCycleFeedback?idCycle=${idCycle}`, {
        method: 'GET',
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `feedback_${idCycle}.docx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        const errorText = await response.text();
        console.error('Download failed:', errorText);
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };

  const getParacaidistaRate = (paracaidista, currentJourneyIndex) => {
    if (!paracaidista || !paracaidista.journey) {
      console.error("Paracaidista o journey no definido.");
      return null;
    }
    
    const currentJourneyData = paracaidista.journey.get(currentJourneyIndex);
  
    if (currentJourneyData && currentJourneyData.cycleJourneyParticipantRate !== undefined) {
      return currentJourneyData.cycleJourneyParticipantRate;
    } else {
      return null;
    }
  };

  const getParacaidistaRemarks = (paracaidista, currentJourneyIndex) => {
    if (!paracaidista || !paracaidista.journey) {
      console.error("Paracaidista o journey no definido.");
      return null;
    }
    
    const currentJourneyData = paracaidista.journey.get(currentJourneyIndex);
  
    if (currentJourneyData && currentJourneyData.cycleJourneyParticipantRate !== undefined) {
      return currentJourneyData.cycleJourneyParticipantRemarks;
    } else {
      return null;
    }
  };

  //TODO: revisar esto, lo dejo comentado porque no sé qué es y no se está usando
  /*const getJourneyWithData = (paracaidista) => {
    if (!paracaidista || !paracaidista.journey || !(paracaidista.journey instanceof Map)) {
      console.error("Paracaidista o su journey no están definidos correctamente.");
      return null;
    }
  
    for (const [journeyNumber, journeyData] of paracaidista.journey.entries()) {
      if (journeyData && typeof journeyData === "object" && Object.keys(journeyData).length > 0) {
        return journeyNumber; // Retorna el número de jornada donde hay datos
      }
    }
  
    console.warn("No se encontraron datos para ninguna jornada.");
    return null; // Retorna null si no encuentra ninguna jornada con datos
  };

  const handleFeedbackUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
        snackActions.error(t('WORKSHEET.NO_FILE'));
        return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('idCycle', formData.idCycle);
    formData.append('hasFeedback', formData.hasFeedback)

    try {
        setOpenLoading(true);
        await serviceFiles.uploadFeedback(formData);
        snackActions.success(t('WORKSHEET.UPLOAD_SUCCESS'));
    } catch (error) {
        console.error('Error during upload:', error);
        snackActions.error(t('WORKSHEET.UPLOAD_ERROR') + ': ' + error.message);
    } finally {
        setOpenLoading(false);
    }
  };*/

  useEffect(() => {
    serviceValuations.getValuation().then(response => {
      if (!response.error) {
        setValuations(response.data)
      }
    })
  }, [])

  useEffect(() => {
    const tempAssistantMedia = [];
    const assistanceTemp = []
    const participantsMapTemp = new Map();
    const paracaidistasMapTemp = new Map();
    const paracaidistasMapTemp2 = new Map();
    let queTeLlevasTemp = 0;
    let gobalGradeTemp = 0;
    let numbLastJourney = 0;
    let peopleWhoRate = 0;
      
    formData.cycleParticipants?.forEach((participant) => {
      queTeLlevasTemp = queTeLlevasTemp + participant.cycleParticipantRate
      if (participant.cycleParticipantRate > 0) {
        peopleWhoRate++
      }
      if (!participantsMapTemp.has(participant.idParticipant) && !participant.isParatrooper) {
        participantsMapTemp.set(participant.idParticipant, participant)
      }
      if (!participantsMapTemp.has(participant.idParticipant) && participant.isParatrooper) {
        paracaidistasMapTemp2.set(participant.idParticipant, participant)
      }     
    });

    formData.cycleJourneys?.forEach((journey, index) => {
      let journeyRates = 0;
      let assist = 0
      let totalNotas = 0;
      journey?.cycleJourneyParticipants?.forEach((cycleJourneyParticipant) => {
      
        if (cycleJourneyParticipant.cycleJourneyAssistance) {
          assist++;
          if (cycleJourneyParticipant.cycleJourneyParticipantRate > 0) {
            journeyRates = journeyRates + cycleJourneyParticipant.cycleJourneyParticipantRate;
            totalNotas++;
          }
        }
        if (participantsMapTemp.has(cycleJourneyParticipant.idParticipant)) {
          let participantTemp = participantsMapTemp.get(cycleJourneyParticipant.idParticipant)

          if ("journeys" in participantTemp) {
            const journeyTemp = participantTemp.journeys

              journeyTemp.set(index + 1, cycleJourneyParticipant)
          } else {
            const journeyTemp = new Map()
            journeyTemp.set(index + 1, cycleJourneyParticipant)
            participantTemp.journeys = journeyTemp
          }
        } else {
          if (paracaidistasMapTemp.has(cycleJourneyParticipant.idParticipant)) {
            const paracaidistaMapTemp = paracaidistasMapTemp.get(cycleJourneyParticipant.idParticipant)

              paracaidistaMapTemp.journey.set(index + 1, cycleJourneyParticipant)
            
          } else {
            if (paracaidistasMapTemp2.has(cycleJourneyParticipant.idParticipant)) {
              const paracaidistaMapTemp = paracaidistasMapTemp2.get(cycleJourneyParticipant.idParticipant)
              
              const journeyTemp = new Map()
              journeyTemp.set(index + 1, cycleJourneyParticipant)

              const paracaidista = {
              journey: journeyTemp,
              participantName: cycleJourneyParticipant.participantName,
              participantSurname: cycleJourneyParticipant.participantSurname,
              companyName : cycleJourneyParticipant.companyName,
              cycleName: cycleJourneyParticipant.cycleName,
              cycleParticipantRate : paracaidistaMapTemp.cycleParticipantRate,
              cycleParticipantRemarks : paracaidistaMapTemp.cycleParticipantRemarks
              };
              paracaidistasMapTemp.set(cycleJourneyParticipant.idParticipant, paracaidista)
            } else {
              const journeyTemp = new Map()
              journeyTemp.set(index + 1, cycleJourneyParticipant)

              const paracaidista = {
              journey: journeyTemp,
              participantName: cycleJourneyParticipant.participantName,
              participantSurname: cycleJourneyParticipant.participantSurname,
              companyName : cycleJourneyParticipant.companyName,
              cycleName: cycleJourneyParticipant.cycleName,
              };
              paracaidistasMapTemp.set(cycleJourneyParticipant.idParticipant, paracaidista)
            }            
          }
        }
        if (index === formData.cycleJourneys.length - 1) {
          gobalGradeTemp = gobalGradeTemp + cycleJourneyParticipant.cycleJourneyParticipantRate

        }
      });
      assistanceTemp.push(assist)
      if (totalNotas > 0)
        tempAssistantMedia.push((journeyRates / totalNotas).toFixed(2))
      else
        tempAssistantMedia.push("")
      if (index === formData.cycleJourneys.length - 1) {

        numbLastJourney = totalNotas;
      }

    });

    paracaidistasMapTemp.forEach((paracaidista, clave) => {
      for (let i = 1; i <= formData.cycleJourneys.length; i++) {
        if (!paracaidista.journey.has(i)) {
          paracaidista.journey.set(i, "")
        }
      }
    });

    if (numbLastJourney > 0) {      
      setQueTeLLevas((queTeLlevasTemp / peopleWhoRate).toFixed(2))
      setGlobalGrade((gobalGradeTemp / peopleWhoRate).toFixed(2))
    } else {
      setQueTeLLevas("")
      setGlobalGrade("")
    }
      
    setParacaidistas(paracaidistasMapTemp);
    setParticipants(participantsMapTemp)
    setAssistantMedia(tempAssistantMedia);
    setAssistance(assistanceTemp)
  }, [formData]);

  const handleChangeSelect = (e, index) => {

    const selectedValuationId = e.target.value;
    const selectedValuation = valuations.find(valuation => valuation.idValuation === selectedValuationId);

    if (!selectedValuation) {
      return; // Manejar el caso de valor no encontrado aquí
    }

    setFormData(prevState => {
      const updatedParticipants = prevState.cycleParticipants.map((participant, i) => {
        if (i === index) {
          return {
            ...participant,
            idValuation: selectedValuationId,
            valuationCode: selectedValuation.valuationCode
          };
        }
        return participant;
      });

      return {
        ...prevState,
        cycleParticipants: updatedParticipants
      };
    });
  }

  const handleChangeSelectEv = (e, index) => {
    idEvaluatorTypeRef.current.value = e.target.value
    const {value} = idEvaluatorTypeRef.current

    setFormData(prevState => {
      const updatedParticipants = prevState.cycleParticipants.map((participant, i) => {
        if (i === index) {
          return {
            ...participant,
            idEvaluator: 0,
            idEvaluatorType: value
          };
        }
        return participant;
      });

      return {
        ...prevState,
        cycleParticipants: updatedParticipants
      };
    });

  }

  const handleOpenIncidentDialog = (journey, index = null) => {
    // Inicializa la descripción del incidente si no existe
    const description = journey?.cycleJourneyParticipantIncidentDesc || '';
  
    setCurrentIncident({ 
      journey, 
      index, 
      idCycle: formData.idCycle, 
      desc: description
    });
  
    setOpenIncidentDialog(true);
  };
  
  

  const saveIncidentDescription = async () => {
    const journey = currentIncident.journey
      const incidentData = {
        idParticipant: journey.idParticipant,
        cycleJourneyParticipantIncident: journey.cycleJourneyParticipantIncident,
        cycleJourneyParticipantIncidentDesc: currentIncident.desc,
        idCycleJourney: journey.idCycleJourney,
        idCycleJourneysParticipants: journey.idCycleJourneysParticipants,
        idCycle: currentIncident.idCycle
      }
  
      const { error, data } = await serviceIncidents.changeFromCycle( incidentData );


    if(!error) {
      setFormData(prevState => {
        const cycleJourneys = prevState.cycleJourneys.map((journey, journeyIndex) => {
          if (journeyIndex === currentIncident.index) {
            const updatedParticipants = journey.cycleJourneyParticipants.map(participant => {
              if (participant.idParticipant === currentIncident.journey.idParticipant) {
                return {
                  ...participant,
                  cycleJourneyParticipantIncidentDesc: data.descriptionIncident,
                  cycleJourneyParticipantIncident: data.idIncident
                };
              }
              return participant;
            });
      
            return {
              ...journey,
              cycleJourneyParticipants: updatedParticipants
            };
          }
          return journey;
        });
      
        return {
          ...prevState,
          cycleJourneys: cycleJourneys
        };
      });
      snackActions.success(t("INCIDENT.UPDATE_INCIDENT"))
    }
   
    setOpenIncidentDialog(false);
  };  

  const updateIncident = data => {
    const idIncident = data.idIncident;
    return serviceIncidents.updateIncident(data); // Usa serviceIncidents en lugar de apiRequest
  };  

  const fetchIncidentDetails = async (incidentId) => {
    try {
        // console.log(`Recuperando detalles para la incidencia con ID: ${incidentId}`);
        // Realiza una solicitud para obtener todos los detalles de la incidencia
        const response = await serviceIncidents.getIncidentsDetail(incidentId);

        // Imprime la respuesta del backend para depuración
        // console.log('Respuesta del backend para detalles de la incidencia:', response);

        if (!response.error && response.data) {
            return response.data;
        } else {
            snackActions.error(response.message || 'Error al recuperar los detalles de la incidencia.');
            return null;
        }
    } catch (error) {
        console.error('Error durante la recuperación de detalles de la incidencia:', error);
        return null;
    }
  };

  const handleCloseIncident = async () => {
      const journey = currentIncident.journey;
      
      // Recupera los detalles completos de la incidencia antes de intentar cerrarla
      const incidentDetails = await fetchIncidentDetails(journey.cycleJourneyParticipantIncident);
      
      // Mantén los valores recuperados junto con los nuevos cambios
      const incidentData = {
          idIncident: journey.cycleJourneyParticipantIncident,
          descriptionIncident: currentIncident.desc || incidentDetails.descriptionIncident,
          openIncident: 0,  // Cambiar solo el estado de OpenIncident a 0
          idCycleJourney: journey.idCycleJourney,
          idParticipant: journey.idParticipant, 
          idCycle: currentIncident.idCycle,
          isOtherCompanyIncident: incidentDetails.isOtherCompanyIncident || 0,

          // Usar los datos recuperados del backend
          idUser: incidentDetails.idUser,
          dateIncident: incidentDetails.dateIncident
      };

      // Debug: Verificar los datos enviados
      // console.log('Datos de la incidencia enviados:', incidentData);

      try {
          const response = await serviceIncidents.updateIncident(incidentData);
          if (!response.error) {
              snackActions.success(t("TEXT.OK_INCIDENT"));
              setOpenIncidentDialog(false);
          } else {
              snackActions.error(response.message);
          }
      } catch (error) {
          snackActions.error(t("TEXT.ERROR_CLOSE_INCIDENT"));
      }
  };

  const handleComent = (coment) => {
    setComentJourney(coment);
    setOpenDialog(true);
  };

  const handleShowFeedback = (feedBack, name, index) => {
    setFeedbackInd(feedBack);
    setNameFeedback(name)
    setParticipantIndex(index)
    setOpenFeedback(true);

  }

  const handleChangeFeedback = event => {
    setFeedbackInd(event.target.value);
  };

  const saveFeedback = () => {
    setFormData(prevState => {
      const updatedParticipants = prevState.cycleParticipants.map((participant, i) => {
        if (i === participantIndex) {
          return {
            ...participant,
            cycleParticipantFeedback: feedbackInd
          };
        }
        return participant;
      });
      return {
        ...prevState,
        cycleParticipants: updatedParticipants
      };
    });
    setOpenFeedback(false);
  };

  const getDocument = async (participant, reportType = 'report') => {
    setOpenLoading(true);

    let servicePromise;

    if (reportType === 'report') {
      const file = {
        idEvaluatorParticipant: participant.idEvaluatorParticipant,
        fileName: participant.participantName + " " + participant.participantSurname + " " + t("LABEL.REPORT"),
        fileType: "participant"
      };
      servicePromise = serviceFiles.getPdfEvaluator(file);

    } else if (reportType === 'diploma') {
      servicePromise = serviceFiles.getDiploma(participant.idParticipant, participant.idCycle, false);
    } else if (reportType === 'diploma_print') {
        servicePromise = serviceFiles.getDiploma(participant.idParticipant, participant.idCycle, true);
    } else if (reportType === 'feedback') {
      servicePromise = serviceFiles.getWordFeedback(participant.idCycle);

    } else {
      setOpenLoading(false);
      snackActions.error(t("EVALUATOR.ERROR_REPORT"));
      return;
    }
  
    try {
      await servicePromise;
    } catch (error) {
      snackActions.error(t('EVALUATOR.ERROR_' + reportType.toUpperCase()));
      throw error; // rethrow the error after handling
    } finally {
      setOpenLoading(false);
    }
  };


    const sendDocument = (participant, reportType = 'report') => {
        setOpenLoading(true);


        let servicePromise;

        if (reportType === 'report') {
            const file = {
                idEvaluatorParticipant: participant.idEvaluatorParticipant,
                fileName: participant.participantName + " " + participant.participantSurname + " " + t("LABEL.REPORT"),
                fileType: "participant"
            };
            servicePromise = serviceFiles.sendPdfEvaluator(file);

        } else {
            setOpenLoading(false);
            snackActions.error(t("EVALUATOR.ERROR_REPORT"));
            return;
        }


        servicePromise
            .then(() => {
                // Lógica en caso de éxito
                snackActions.success(t("TEXT.REPORT_SEND"));
            })
            .catch(error => {
                snackActions.error(t('EVALUATOR.ERROR_' + reportType.toUpperCase()));
            })
            .finally(() => {
                setOpenLoading(false);
            });
    };

  const handleShowParticipantDetail = ({idParticipant, type}) => {
    serviceParticipants.getParticipantsDetails(idParticipant).then(response => {
      if (!response.error) {
        localStorage.setItem('participantDetailData', JSON.stringify({ data: response.data, type }));

        const newWindow = window.open('/participantDetail', '_blank');
        
        if (!newWindow) {
          navigate('/participantDetail');
        }
      }
    });
  }

  const checkIsParatrooper = (data) => {
    const journeyMap = data?.journey;
        
    if (!journeyMap || !(journeyMap instanceof Map)) {
        return false;
    }

    let filledJourneys = 0;
    
    for (const [key, value] of journeyMap) {
      if (value && typeof value === 'object' && Object.keys(value).length > 0 && value.idCycleOrigin !== null) {
          filledJourneys++;
      }
    }

    return filledJourneys === 1;
  }

  const participantsRender = () => {
    if (participants !== null && participants !== undefined) {      
      return (([...participants].map(([idParticipant, participant], index) => (
        <React.Fragment key={`participantData${index}`}>
          <TableRow>
            <TableCell 
              className={classes.stickyTableCell} 
              style={{cursor: 'pointer'}}
              onClick={() => {
                handleShowParticipantDetail({
                  idParticipant: participant.idParticipant,
                })
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {participant.participantName}{" "}
                {participant.participantSurname}
              </span>
              <br />
              {formData.cycleCompanies.length !== 1 && (
                <span>
                  {participant.companyName === "-OTRA-" ? participant.participantOtherCompany : participant.companyName}
                </span>
              )}
            </TableCell>

            {participant.journeys && ([...participant.journeys].map(([journeyNumb, journey], i) => {                          
              return (<React.Fragment key={`journeyData${i}`}>
                <TableCell align="center">
                  <span
                    style={{
                      color: journey.cycleJourneyAssistance ? 'black' : 'red',
                      ...(journey.cycleJourneyAssistance ? {} : { fontWeight: 'bold' }),
                      cursor: 'pointer'
                    }}
                    onClick={() => handleOpenAssistanceDialog(journey)}
                  >
                    {journey.cycleJourneyAssistance ? 'A' : 'X'}
                    
                    {/* Se verá en un futuro si se cambia
                    {journey.cycleJourneyParticipantIncidentDesc && (journey.cycleJourneyParticipantIncidentDesc.includes("Paracaidista al grupo") || journey.cycleJourneyParticipantIncidentDesc.includes("Paratrooper to group")) ? 'P' : (journey.cycleJourneyAssistance ? 'A' : 'X')}
                     Uso el inicio de la descriptionIncident para un paracaidista en el grupo de origen */}

                  </span>
                </TableCell>
                
                {closeJourney[i] === 2 && (
                  <TableCell sx={{borderRight: "1px solid #0085B4"}} align="center">
                    <Tooltip
                      title={
                        journey?.cycleJourneyParticipantIncidentDesc ? (
                          <span>
                            {journey.cycleJourneyParticipantIncidentDesc
                              .split('\n')
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </span>
                        ) : ''  // Si no hay descripción, el Tooltip estará vacío.
                      }
                      disableHoverListener={!journey?.cycleJourneyParticipantIncidentDesc}  // Deshabilita el Tooltip si no hay contenido
                    >
                      <ReportProblemRoundedIcon
                        color="error"
                        style={{
                          cursor: 'pointer',
                          ...(journey.cycleJourneyParticipantIncidentDesc
                            ? {}
                            : { color: '#808080' }  // Cambia el color si no hay descripción
                          ),
                        }}
                        onClick={() => handleOpenIncidentDialog(journey, i)}  // Sigue abriendo el diálogo al hacer clic
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {closeJourney[i] !== 2 && (
                  <TableCell align="center">
                    <Tooltip
                      title={
                        journey?.cycleJourneyParticipantIncidentDesc ? (
                          <span>
                            {journey.cycleJourneyParticipantIncidentDesc
                              .split('\n')
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </span>
                        ) : ''  // Tooltip vacío si no hay contenido
                      }
                      disableHoverListener={!journey?.cycleJourneyParticipantIncidentDesc}  // Desactiva el Tooltip si no hay descripción
                    >
                      <ReportProblemRoundedIcon
                        color="error"
                        style={{
                          cursor: 'pointer',
                          color: !journey.cycleJourneyAssistance && journey.idCycleOrigin !== null ? 'lightgreen' : journey.cycleJourneyParticipantIncidentDesc ? 'error' : '#808080'  // Cambia el color si no hay contenido
                        }}
                        onClick={() => handleOpenIncidentDialog(journey, i)}  // El ícono sigue abriendo el diálogo
                      />
                    </Tooltip>
                  </TableCell>
                )}


                {formData.cycleJourneys.length - 1 > i &&
                  closeJourney[i] !== 2 && (
                    <>
                      <TableCell align="center">
                        {(journey?.cycleJourneyAssistance || journey?.idCycleJourneySER !== null) && (
                          <span style={{color: journey?.cycleJourneyParticipantRate && parseFloat(journey?.cycleJourneyParticipantRate) < 7 ? 'red' : 'black', ...(journey?.cycleJourneyParticipantRate > 7 ? {} : {fontWeight: 'bold'})}}>
                            {journey?.idCycleJourneySER !== null  ? 'R' : journey?.cycleJourneyParticipantRate }
                          </span>
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid #0085B4",
                        }}
                      >
                        {journey?.cycleJourneyParticipantRemarks && (
                          <IconButton
                            color="inherit"
                            onClick={() =>
                              handleComent(
                                journey.cycleJourneyParticipantRemarks
                              )
                            }
                          >
                            <Feed />
                          </IconButton>
                        )}
                      </TableCell>
                    </>
                  )}

                {(formData.cycleJourneys.length - 1) == i && (
                  <>
                    <TableCell
                      sx={{borderLeft: "1px solid #0085B4"}}
                      align="center"
                    >
                      <span style={{
                        color: journey?.cycleJourneyAssistance === false
                          ? 'green'
                          : participant.cycleParticipantRate && parseFloat(participant.cycleParticipantRate) < 7
                            ? 'red'
                            : 'black',
                        ...(participant.cycleParticipantRate > 7 ? {} : { fontWeight: 'bold' })
                      }}>
                        {participant.cycleParticipantRate}
                      </span>
                    </TableCell>
                    {closeJourney[closeJourney.length - 2] === 2 && (
                      <TableCell
                        sx={{borderRight: "1px solid #0085B4"}}
                        align="center"
                      >
                        <span style={{
                          color: journey?.cycleJourneyAssistance === false
                            ? 'green'
                            : journey?.cycleJourneyParticipantRate && parseFloat(journey?.cycleJourneyParticipantRate) < 7
                              ? 'red'
                              : 'black',
                          ...(journey?.cycleJourneyParticipantRate > 7 ? {} : { fontWeight: 'bold' })
                        }}>
                          {journey?.cycleJourneyParticipantRate}
                        </span>
                      </TableCell>
                    )}
                    {closeJourney[closeJourney.length - 2] !== 2 && (
                      <>
                        <TableCell align="center">
                        <span style={{
                          color: journey?.cycleJourneyAssistance === false
                            ? 'green'
                            : journey?.cycleJourneyParticipantRate && parseFloat(journey?.cycleJourneyParticipantRate) < 7
                              ? 'red'
                              : 'black',
                          ...(journey?.cycleJourneyParticipantRate > 7 ? {} : { fontWeight: 'bold' })
                        }}>
                          {journey?.idCycleJourneySER !== null ? 'R' : journey?.cycleJourneyParticipantRate}
                        </span>
                        </TableCell>
                        <TableCell align="center">
                          {journey?.cycleJourneyParticipantRemarks && (
                            <IconButton
                              color="inherit"
                              onClick={() =>
                                handleComent(
                                  journey.cycleJourneyParticipantRemarks
                                )
                              }
                            >
                              <Feed />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {participant.cycleParticipantRemarks && (
                            <IconButton
                              color="inherit"
                              onClick={() =>
                                handleComent(participant.cycleParticipantRemarks)
                              }
                            >
                              <Feed />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{borderRight: "1px solid #0085B4"}}
                          align="center"
                        ></TableCell>
                      </>
                    )}
                  </>
                )}

              </React.Fragment>
            )}))}
            <TableCell align="center">
              {participant?.numReceivers != 0 && (participant?.totalReceiverResponses > 3 || participant?.numReceivers < 4 && participant?.totalReceiverResponses == participant?.numReceivers) && participant.nota_Total !== null && (
                <>
                  {
                    participant?.nota_Total.toFixed(1)
                  }
                </>
              )}
            </TableCell>
            <TableCell align="center"
              sx={{borderRight: closeJourney[closeJourney.length - 1] === 2 ? "1px solid #0085B4" : ""}}>
              {participant?.numReceivers != 0 && (participant?.totalReceiverResponses > 3 || participant?.numReceivers < 4 && participant?.totalReceiverResponses == participant?.numReceivers) && participant.nota_QTR !== null && (
                <>
                  {
                    participant?.nota_QTR.toFixed(1)
                  }
                </>
              )}
            </TableCell>
            {closeJourney[closeJourney.length - 1] !== 2 && (
              <>
                <TableCell align="center">
                   {/*&& (participant?.totalReceiverResponses > 3 || participant?.numReceivers < 4 && participant?.totalReceiverResponses == participant?.numReceivers) &&*/}
                  {participant?.numReceivers != 0 && participant.nota_AutoEval && (
                    <>
                      {
                        participant?.nota_AutoEval.toFixed(1)
                      }
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                    {participant?.numReceivers != 0 && participant.isAutoevalDone == 1 &&
                    (participant?.totalReceiverResponses > 3 || participant?.numReceivers < 4 && participant?.totalReceiverResponses == participant?.numReceivers)
                     && participant.idEvaluatorType && (
                      <IconButton
                        color="inherit"
                        onClick={() => getDocument(participant, 'report')}
                      >
                        <Summarize />
                      </IconButton>
                    )}
                </TableCell>
                <TableCell align="center">
                    {participant?.numReceivers != 0 && participant.isAutoevalDone == 1 &&
                        (participant?.totalReceiverResponses > 3 || participant?.numReceivers < 4 && participant?.totalReceiverResponses == participant?.numReceivers)
                        && participant.idEvaluatorType && (
                            <IconButton
                                color="inherit"
                                onClick={() => handleOpenConfirmDialog(participant)}
                            >
                                <MailRounded />
                            </IconButton>
                        )}
                </TableCell>
                <TableCell
                  sx={{borderRight: "1px solid #0085B4"}}
                  align="center"
                >
                  {participant?.idEvaluatorType === 1 || participant?.idEvaluatorType === 2 ? (
                    <Select
                      id='idEvaluatorType'
                      ref={idEvaluatorTypeRef}
                      size='small'
                      name='idEvaluatorType'
                      fullWidth
                      value={participant?.idEvaluatorType || ''}
                      onChange={(e) => handleChangeSelectEv(e, index)}
                    >
                      {formData?.evaluatorTypes?.map((evaluatorType, i) => (
                        <MenuItem
                          key={evaluatorType.idEvaluatorType}
                          value={evaluatorType.idEvaluatorType}
                        >
                          {evaluatorType.evaluatorTypeName}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : null}
                </TableCell>

              </>
            )}
            <TableCell
              sx={{borderRight: "1px solid #0085B4"}}
              align="center"
            >
              <Select
                // labelId='cycleParticipantValoration'
                // label={t('LABEL.VALUATIONS')}
                value={valuations.some(v => v.idValuation === participant?.idValuation) ? participant.idValuation : ''} //Quita la advertencia de out-of-range
                name='idValuation'
                onChange={(e) => handleChangeSelect(e, index)}
                size='small'
                fullWidth
              >
                {valuations.map((valuation, i) => (
                  <MenuItem key={`valuation-${valuation.idValuation}-${i}`} value={valuation.idValuation} data-code={valuation.valuationCode}>
                    {valuation.valuationCode}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell align="center">
              <IconButton
                color="inherit"
                onClick={() =>
                  handleShowFeedback(
                    participant.cycleParticipantFeedback,
                    participant.participantName + " " + participant.participantSurname,
                    index
                  )
                }
              >
                <Flag />
              </IconButton>
            </TableCell>
            <TableCell align="center"  style={{ whiteSpace: "nowrap" }}>
              <IconButton
                color="inherit"
                onClick={() => getDocument(participant, 'diploma')}
              >
                <CardMembershipIcon />
              </IconButton>
              <IconButton
                    color="inherit"
                    onClick={() => getDocument(participant, 'diploma_print')}
              >
                <PrintIcon style={{ cursor: 'pointer' }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))
      ))
    }
  };

  const paracaidistasRender = () => {
    if (paracaidistas !== null && paracaidistas !== undefined) {    
      return (([...paracaidistas].map(([idParacaidista, paracaidista], index) => (        
        <React.Fragment key={`paracaidistasDataTotal${index}`}>
          <TableRow sx={{backgroundColor: paracaidista.cycleName == null ? "#F7ECC8" : 'inherit'}}>

            <TableCell className={classes.stickyTableCell}
              sx={{
                backgroundColor:  paracaidista.cycleName == null ? '#f7ecc8 !important' : 'inherit',
              }}
              style={{cursor: 'pointer'}}
              onClick={() => {
                handleShowParticipantDetail({
                  idParticipant: idParacaidista,
                })
              }}
            >
              {paracaidista.cycleName != null && checkIsParatrooper(paracaidista) && (
                  <Tooltip title={paracaidista.cycleName}>
                    <Paragliding fontSize="small" />
                  </Tooltip>
              )}
              <span style={{ fontWeight: "bold" }}>
                {paracaidista.participantName}{" "}
                {paracaidista.participantSurname}
                </span>
                <br />
                {formData.cycleCompanies.length !== 1 && (
                    <span>
                      {paracaidista.companyName === "-OTRA-" ? paracaidista.participantOtherCompany : paracaidista.companyName}
                    </span>
                )}
            </TableCell>
            {Array.from({length: formData.cycleJourneys.length}).map((_, i) => {
              const journey = paracaidista.journey.get(i + 1);
              return (
                <React.Fragment key={`journeyParachute${i}`}>
                  <TableCell align="center">
                  {Object.keys(journey).length !== 0 && (
                      <span style={{color: journey.cycleJourneyAssistance ? 'black' : 'red', ...(journey.cycleJourneyAssistance ? {} : {fontWeight: 'bold'})}}>
                        {journey.cycleJourneyAssistance ? 'A' : 'X'}
                      </span>
                    )}
                  </TableCell>
                  {closeJourney[i] === 2 && (
                    <TableCell
                      sx={{borderRight: "1px solid #0085B4"}}
                      align="center"
                    >
    
                          {Object.keys(journey).length !== 0 ? (
                            <Tooltip
                              title={
                                journey.cycleJourneyParticipantIncidentDesc ? (
                                  <span>
                                    {journey.cycleJourneyParticipantIncidentDesc
                                      .split('\n')
                                      .map((line, index) => (
                                        <React.Fragment key={index}>
                                          {line}
                                          <br />
                                        </React.Fragment>
                                      ))}
                                  </span>
                                ) : ''
                              }
                              disableHoverListener={!journey.cycleJourneyParticipantIncidentDesc}  // Desactiva el Tooltip si no hay descripción
                            >
                              <ReportProblemRoundedIcon
                                color="error"
                                style={{
                                  cursor: 'pointer',
                                  color: journey.cycleJourneyParticipantIncidentDesc ? 'error' : '#808080',  // Cambia el color si no hay contenido
                                }}
                                onClick={() => handleOpenIncidentDialog(journey, i)}  // El ícono sigue abriendo el diálogo
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                    
                    </TableCell>
                  )}
                  {closeJourney[i] !== 2 && (
                    <TableCell align="center">
             
                          {Object.keys(journey).length !== 0 ? (
                            <Tooltip
                              title={
                                journey.cycleJourneyParticipantIncidentDesc ? (
                                  <span>
                                    {journey.cycleJourneyParticipantIncidentDesc
                                      .split('\n')
                                      .map((line, index) => (
                                        <React.Fragment key={index}>
                                          {line}
                                          <br />
                                        </React.Fragment>
                                      ))}
                                  </span>
                                ) : ''  // No muestra el Tooltip si no hay contenido
                              }
                              disableHoverListener={!journey.cycleJourneyParticipantIncidentDesc}  // Desactiva el Tooltip si no hay descripción
                            >
                              <span>  {/* Se usa un span para asegurarse de que el Tooltip no interfiera con el icono siempre visible */}
                                <ReportProblemRoundedIcon
                                  color="error"
                                  style={{
                                    cursor: 'pointer',
                                    ...(journey.cycleJourneyParticipantIncidentDesc ? {} : { color: '#808080' }),  // Cambia el color si no hay descripción
                                  }}
                                  onClick={() => handleOpenIncidentDialog(journey, i)}  // Mantiene la funcionalidad del diálogo al hacer clic
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                       
                    </TableCell>
                  )}
                  {formData.cycleJourneys.length - 1 > i &&
                    closeJourney[i] !== 2 && (
                      <>
                        <TableCell align="center">
                          {journey.cycleJourneyParticipantRate && (
                            <span style={{color: journey?.cycleJourneyParticipantRate && parseFloat(journey?.cycleJourneyParticipantRate) < 7 ? 'red' : 'black', ...(journey?.cycleJourneyParticipantRate > 7 ? {} : {fontWeight: 'bold'})}}>
                              {journey?.cycleJourneyAssistance && (
                                <>
                                  {
                                    journey?.cycleJourneyParticipantRate
                                  }
                                </>
                              )}
                            </span>)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{borderRight: "1px solid #0085B4"}}
                        >
                          {journey?.cycleJourneyParticipantRemarks && (
                            <IconButton
                              color="inherit"
                              onClick={() =>
                                handleComent(journey.cycleJourneyParticipantRemarks)
                              }
                            >
                              <Feed />
                            </IconButton>
                          )}
                        </TableCell>
                      </>
                    )}

                </React.Fragment>)
            })}
            <TableCell
              sx={{borderLeft: "1px solid #0085B4"}}
              align="center"
            >
              <span style={{
                color: (() => {
                  const rate = parseFloat(
                    formData.cycleJourneys?.[formData.cycleJourneys.length - 1]?.cycleJourneyParticipantRate
                  );
                  if (isNaN(rate)) return 'black'; // Valor inválido, por defecto 'black'
                  return rate < 7 ? 'red' : 'black';
                })(),
                fontWeight: (() => {
                  const rate = parseFloat(
                    formData.cycleJourneys?.[formData.cycleJourneys.length - 1]?.cycleJourneyParticipantRate
                  );
                  if (isNaN(rate)) return 'normal'; // Valor inválido, no aplicar bold
                  return rate > 7 ? 'normal' : 'bold';
                })()
              }}>
                {paracaidista?.cycleParticipantRate || ''}
              </span>
            </TableCell>
            {closeJourney[closeJourney.length - 2] === 2 && (
              <TableCell
                sx={{borderRight: "1px solid #0085B4"}}
                align="center"
              ></TableCell>
            )}
            {closeJourney[closeJourney.length - 2] !== 2 && (
              <>
                <TableCell align="center">                  
                  <span style={{
                    color: (() => {
                      const rate = parseFloat(
                        formData.cycleJourneys?.[formData.cycleJourneys.length - 1]?.cycleJourneyParticipantRate
                      );
                      if (isNaN(rate)) return 'black'; // Valor inválido, por defecto 'black'
                      return rate < 7 ? 'red' : 'black';
                    })(),
                    fontWeight: (() => {
                      const rate = parseFloat(
                        formData.cycleJourneys?.[formData.cycleJourneys.length - 1]?.cycleJourneyParticipantRate
                      );
                      if (isNaN(rate)) return 'normal'; // Valor inválido, no aplicar bold
                      return rate > 7 ? 'normal' : 'bold';
                    })()
                  }}>
                    {getParacaidistaRate(
                      paracaidista, 
                      formData.cycleJourneys?.[formData.cycleJourneys.length - 1]?.cycleJourneyNumber
                    )}
                  </span>
                </TableCell>
                <TableCell align="center">                  
                  {getParacaidistaRemarks(paracaidista, formData.cycleJourneys?.[formData.cycleJourneys.length - 1]?.cycleJourneyNumber) && (
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        handleComent(
                          getParacaidistaRemarks(paracaidista, formData.cycleJourneys?.[formData.cycleJourneys.length - 1]?.cycleJourneyNumber)
                        )
                      }
                    >
                      <Feed />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="center">
                  {paracaidista?.cycleParticipantRemarks && (
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        handleComent(
                          paracaidista?.cycleParticipantRemarks
                        )
                      }
                    >
                      <Feed />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell
                  sx={{borderRight: "1px solid #0085B4"}}
                  align="center"
                ></TableCell>
              </>
            )}
            <TableCell align="center"></TableCell>
            {closeJourney[closeJourney.length - 1] === 2 && (
              <TableCell
                sx={{borderRight: "1px solid #0085B4"}}
                align="center"
              ></TableCell>
            )}
            {closeJourney[closeJourney.length - 1] !== 2 && (
              <>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell
                  sx={{borderRight: "1px solid #0085B4"}}
                  align="center"
                ></TableCell>
              </>
            )}
            <TableCell
              sx={{borderRight: "1px solid #0085B4"}}
              align="center"
            ></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </React.Fragment>
      ))
      ));
    }
  };

  const endRender = () => {
    return (<TableRow className={classes.tableBodySearch}>
      <TableCell className={classes.stickyTableCell}
        sx={{
          backgroundColor: '#ededed !important',
          fontWeight: 'bold'
        }}
        align="center"
      >
        {t("MENU_ADMIN.PARTICIPANTS")}
      </TableCell>
      {formData.cycleJourneys?.map((journeyData, i) => {
        return (

          <React.Fragment key={`journeyDataTotal${i}`}>
            <TableCell sx={{fontWeight: "bold"}} align="center">
              {assistance[i]}
            </TableCell>
            {closeJourney[i] !== 2 && (
              <TableCell align="center"></TableCell>
            )}
            {closeJourney[i] === 2 && (
              <TableCell
                sx={{borderRight: "1px solid #0085B4"}}
                align="center"
              ></TableCell>
            )}
            {formData.cycleJourneys.length - 1 > i && closeJourney[i] !== 2 && (
              <>
                <TableCell sx={{fontWeight: "bold"}} align="center">
                  <span style={{color: (assistantMedia[i] ?? 0) < 7 ? 'red' : 'black'}}>
                    {Math.round((assistantMedia[i] ?? 0) * 10) / 10}
                  </span>
                </TableCell>
                <TableCell
                  colSpan={1}
                  sx={{borderRight: "1px solid #0085B4"}}
                ></TableCell>
              </>
            )}
          </React.Fragment>
        );
      })}

      <TableCell
        sx={{fontWeight: "bold", borderLeft: "1px solid #0085B4"}}
        align="center"
      >
        <span style={{color: (queTeLLevas ?? 0) < 7 ? 'red' : 'black'}}>
          {Math.round((queTeLLevas ?? 0) * 10) / 10}
        </span>
      </TableCell>
      {closeJourney[closeJourney.length - 2] === 2 && (
        <TableCell sx={{borderRight: "1px solid #0085B4"}}>
          <span style={{color: (globalGrade ?? 0) < 7 ? 'red' : 'black'}}>
            {Math.round((globalGrade ?? 0) * 10) / 10}
          </span>
        </TableCell>
      )}
      {closeJourney[closeJourney.length - 2] !== 2 && (
        <>
          <TableCell colSpan={1} sx={{fontWeight: "bold"}} align="center">
            <span style={{color: (globalGrade ?? 0) < 7 ? 'red' : 'black'}}>
              {Math.round((globalGrade ?? 0) * 10) / 10}
            </span>
          </TableCell>
          <TableCell sx={{fontWeight: "bold"}} align="center"></TableCell>
          <TableCell
            sx={{borderRight: "1px solid #0085B4"}}
            colSpan={2}
          ></TableCell>
        </>
      )}
      <TableCell sx={{fontWeight: "bold"}} align="center"></TableCell>
      {closeJourney[closeJourney.length - 1] === 2 && (
        <TableCell
          sx={{fontWeight: "bold", borderRight: "1px solid #0085B4"}}
          align="center"
        ></TableCell>
      )}
      {closeJourney[closeJourney.length - 1] !== 2 && (
        <>
          <TableCell sx={{fontWeight: "bold"}} align="center"></TableCell>
          <TableCell sx={{fontWeight: "bold"}} align="center"></TableCell>
          <TableCell sx={{fontWeight: "bold"}} align="center"></TableCell>
          <TableCell sx={{ fontWeight: "bold" }} align="center"></TableCell>
          <TableCell
            sx={{fontWeight: "bold", borderRight: "1px solid #0085B4"}}
            align="center"
          ></TableCell>
        </>
      )}
      <TableCell sx={{borderRight: "1px solid #0085B4"}}></TableCell>

      <TableCell sx={{ fontWeight: "bold" }} align="center">
        <Box display="flex" alignItems="center">
          <Tooltip title={getFeedbackTooltipTitle()}>
            <IconButton
              color="inherit"
              onClick={handleFlag}
            >
              <Flag />
            </IconButton>
          </Tooltip>
          {/* Comentado porque no funciona el subir el Feedback
          <Tooltip title={t('LABEL.UPLOAD_FEEDBACK')}>
            <IconButton
              color="inherit"
              component="label"
              style={{ cursor: 'pointer', marginLeft: '-10px' }}
            >
              <CloudUploadIcon />
              <input
                hidden
                accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleFeedbackUpload}
                type="file"
              />
            </IconButton>
          </Tooltip>
          */}
        </Box>
      </TableCell>
      
      <TableCell align="center"></TableCell>
    </TableRow>)
  }

  return (
    <>
      
      <Dialog
        open={openAssistanceDialog}
        onClose={() => setOpenAssistanceDialog(false)}
        hasCloseBtn={false}
        title={currentParticipant?.cycleJourneyAssistance ? t('CONFIRM.ADD_ATTENDANCE') : t('CONFIRM.DELETE_INCIDENT')}
        actions={
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8, width: '100%' }}>
            <Button
              variant='contained'
              onClick={() => setOpenAssistanceDialog(false)}
              color='error'
            >
              {t('BUTTON.CANCEL')}
            </Button>
            <Button
              variant='contained'
              onClick={handleConfirmAssistanceChange}
            >
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      >
        <Box p={2} textAlign="center">
          {currentParticipant?.cycleJourneyAssistance ? t('CONFIRM.REMOVE_ASSISTANCE_MESSAGE') : t('CONFIRM.DELETE_INCIDENT')}
        </Box>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        hasCloseBtn={false}
        title={t("CONFIRM.SEND_EMAIL")}
        actions={
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8, width: '100%' }}>
            <Button
              variant='contained'
              onClick={() => setOpenConfirmDialog(false)}
              sx={{
                backgroundColor: '#da4f49',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#983733',
                },
              }}
            >
              {t("LABEL.FALSE")}
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                setOpenConfirmDialog(false);
                sendDocument(currentParticipant, 'report');
              }}
              sx={{
                backgroundColor: '#009900',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#1b5e20',
                },
              }}
            >
              {t("LABEL.TRUE")}
            </Button>
          </Box>
        }
      >
        <Box p={2} textAlign="center">
          {t("CONFIRM.SEND_EMAIL")}
        </Box>
      </Dialog>

      <Dialog
        open={openDialog}
        hasCloseBtn={true}
        height={350}
        width={500}
        title={t("LABEL.COMMENT")}
        content={
          <TextField
            name='comentJourney'
            value={comentJourney || ''}
            size='small'
            fullWidth
            multiline
            rows={8}
            variant='outlined'
          />
        }
        close={() => setOpenDialog(false)}
      />

      <Dialog
        title={t("TEXT.LOADING")}
        open={openLoading}
        hasCloseBtn={false}
        content={
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Box>
              <CircularProgress />
            </Box>
            <br />
            <Box>
              {t("EVALUATOR.LOADING_DATA")}
            </Box>
          </div>
        }
      />

      <Dialog
        open={openFeedback}
        hasCloseBtn={false}
        width='60%'
        height='fit-content'
        centerContent={true}
        title={t('LABEL.FEEDBACK')}
        subtitle={nameFeedback}
        close={() => setOpenFeedback(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenFeedback(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={saveFeedback}>
              {t('BUTTON.ACCEPT')}
            </Button>
          </Box>
        }
        content={
          <TextField
            name='feedbackIndividual'
            value={feedbackInd || ''}
            size='small'
            fullWidth
            multiline
            rows={8}
            variant='outlined'
            onChange={handleChangeFeedback}
          />
        }
      />

      <Dialog
        open={openIncidentDialog}
        width={500}
        hasCloseBtn={false}
        close={() => setOpenIncidentDialog(false)}      
        title={t('INCIDENT.NEW_INCIDENT')}
        content={
          <TextField
            multiline
            fullWidth
            name='descriptionIncident'
            size='small'
            rows={6}
            inputProps={{ maxLength: 255 }}
            value={currentIncident?.desc || ''}
            onChange={(e) => setCurrentIncident({...currentIncident, desc: e.target.value})}
          />

        }
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenIncidentDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>            
            {currentIncident?.journey?.cycleJourneyParticipantIncident && (
              <>
                <Box mr={1}>
                  <Button
                    variant='contained'
                    onClick={() => setOpenDeleteConfirmDialog(true)}
                    color='warning'
                  >
                    {t('BUTTON.DELETE')}
                  </Button>
                </Box>
                <Box mr={1}>
                  <Button
                    variant='contained'
                    color='success'
                    onClick={handleCloseIncident} // Solo se muestra si existe idIncident
                  >
                    {t('BUTTON.CLOSE_INCIDENT')}
                  </Button>
                </Box>
              </>
            )}
            <Button
              variant='contained'
              onClick={saveIncidentDescription}
            >
              {t('BUTTON.SAVE')}
            </Button>
          </Box>
        }
      />

      <Dialog
        open={openDeleteConfirmDialog}
        onClose={() => setOpenDeleteConfirmDialog(false)} // Mantén esta línea si quieres que el diálogo se cierre al hacer clic fuera del cuadro
        title={t('CONFIRM.TITLE_INCIDENT')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
        hasCloseBtn={false}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDeleteConfirmDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button
              variant='contained'
              onClick={deleteIncident}
            >
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      >
        {t('CONFIRM.UNRECOVERY_DELETE')}
      </Dialog>


      {participantsRender()}
      {paracaidistasRender()}
      {endRender()}
    </>
  );

};

export default ParticipantsData;
